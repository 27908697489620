import React from 'react'
import PropTypes from "prop-types"

import arrowRight from "../img/arrow-right.svg"
import play from "../img/play.svg"

const CTAButton = (props) => {
    const {
        text,
        link,
        noIcon = false,
        playIcon = false,
        buttonSize
    } = props
    return(
        <>
            <div className="block">
                { link && (
                    <div className="my-4">
                        <a className={`button is-link ${buttonSize}`} href={link || '#'}>
                            { text }
                            { !noIcon ? (
                                <div className="icon ml-3">
                                    <img src={playIcon ? play : arrowRight} alt="Navigate" />
                                </div>
                            ) : React.Fragment}
                        </a>
                    </div>
                  )}
            </div>
        </>
    )
}

CTAButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  noIcon: PropTypes.bool,
  playIcon: PropTypes.bool,
  buttonSize: PropTypes.string,
}

export default CTAButton