import React from 'react'
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";

import CTAButton from "./CTAButton";
import greenCheck from "../img/green-check.svg";

const CenteredBlurbsSection = (props) => {
    const {
        heading,
        subheading,
        content,
        buttonLink,
        buttonText,
        noIcon,
        useImage,
        image,
    } = props
    return (
        <>
            <div className="container mt-6">
                <div className="columns is-multiline is-vcentered is-centered">
                    <div className="columns is-centered is-vcentered">
                        <div className="column is-8 has-text-centered">
                            <div className="block">
                                <h2 className="title is-2 has-text-weight-bold">{ heading }</h2>
                            </div>
                            { subheading &&
                                <div className="block">
                                    <h4 className="subtitle">{ subheading }</h4>
                                </div>
                            }
                        </div>
                    </div>
                    { useImage ? (
                        <div className="column is-8 has-text-centered">
                            <img className="img-125" src={image.publicURL} alt={'involve the right people'} />
                        </div>
                    ) : React.Fragment}
                    <div className="column is-7 mb-4">
                        {
                            content.map((elem, index) => {
                                return (
                                    <div key={index} className="columns is-centered is-vcentered">
                                        {!noIcon ? (
                                            <div className="column is-2">
                                                <img src={elem.image.publicURL} alt={`closing-${index}`}/>
                                            </div>
                                        ) : <img src={greenCheck} alt="Included" />}
                                        <div className={"column py-5 px-6"}>
                                            <ReactMarkdown className={"is-size-6 has-text-left"}>{elem.text}</ReactMarkdown>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="column has-text-centered">
                    <CTAButton link={buttonLink} text={buttonText} />
                </div>
            </div>
        </>
    )
}

CenteredBlurbsSection.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.array,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  noIcon: PropTypes.bool,
}

export default CenteredBlurbsSection