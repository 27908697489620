import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

import ThreeBlurbsSection from "../components/ThreeBlurbsSection";
import CenteredBlurbsSection from "../components/CenteredBlurbsSection";
import SimpleHeader from "../components/SimpleHeader";
import ViewPricingSection from "../components/ViewPricingSection";
import HashtagHidden from "../components/HashtagHidden";

// eslint-disable-next-line
export const CompensationReviewBudgetingPlanningPageTemplate = (props) => {
  const { intro, closing, blurbs, pricing } = props

  return (
      <>
          <section className="section is-small no-padding-bottom">
              <div className="container">
                  <Helmet>
                      <meta charSet="utf-8" />
                      <title>Compensation Review: Budgeting & Planning - Kamsa</title>
                      <meta property="og:title" content="Compensation Review: Budgeting & Planning - Kamsa" />
                      <meta name="description" content="Kamsa is a compensation management system that combines global salary data & expert support from compensation consultants for simplified compensation planning." />
                  </Helmet>
              </div>
          </section>
          {/* INTRO START */}
          <section className="section section--gradient is-small">
              <div className="container">
                <div className="columns">
                  <SimpleHeader
                    heading={intro.heading}
                    header={intro.header}
                    subheader={intro.description}
                    buttonLink={intro.buttonLink}
                    buttonText={intro.buttonText}
                    columnSize={'is-7'}
                    isPageHeader
                  />
                </div>
              </div>
          </section>
          {/* INTRO FINISH */}
          {/* BLURBS START */}
           <section className="section is-small mb-6">
               <div className="container">
                   <div className="columns">
                       <ThreeBlurbsSection
                           heroImage={blurbs.heroImage}
                           heading={blurbs.heading}
                           header={blurbs.header}
                           description={blurbs.description}
                           buttonLink={blurbs.buttonLink}
                           buttonText={blurbs.buttonText}
                           blurbs={blurbs.blurbs}
                           isHeader
                           replaceTextWithImage
                           textColumnSize={'is-5'}
                       />
                   </div>
               </div>
           </section>
          {/* BLURBS END */}
          {/* CLOSING START */}
          <section className="section section--gray is-small">
              <div className="box">
                  <CenteredBlurbsSection
                      heading={closing.heading}
                      content={closing.content}
                      buttonLink={closing.buttonLink}
                      buttonText={closing.buttonText}
                      useImage={closing.useImage}
                      image={closing.heroImage}
                      noIcon
                  />
              </div>
          </section>
          {/* CLOSING END */}
          {/* PRICING START */}
          <section className="section section--gradient is-medium">
              <ViewPricingSection
                  title={pricing.title}
                  subtitle={pricing.subtitle}
                  buttonLink={pricing.buttonLink}
                  buttonText={pricing.buttonText}
              />
          </section>
          {/* PRICING END */}
      </>
  );
};

CompensationReviewBudgetingPlanningPageTemplate.propTypes = {
  intro: PropTypes.shape({
    heading: PropTypes.string,
    header: PropTypes.string,
    description: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  blurbs: PropTypes.shape({
    heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    heading: PropTypes.string,
    header: PropTypes.string,
    description: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    blurbs: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            title: PropTypes.string,
            text: PropTypes.string,
        })
    )
  }),
  closing: PropTypes.shape({
    heading: PropTypes.string,
    content: PropTypes.array,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    useImage: PropTypes.bool
  }),
  pricing: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
  })
};

const CompensationReviewBudgetingPlanningPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <CompensationReviewBudgetingPlanningPageTemplate
          intro={frontmatter.crbpPageIntro}
          blurbs={frontmatter.crbpPageBlurbs}
          closing={frontmatter.crbpPageClosing}
          pricing={frontmatter.crbpPagePricing}
      />
      <HashtagHidden hashtags={frontmatter.crbpPageHashtags} />
    </Layout>
  );
};

CompensationReviewBudgetingPlanningPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CompensationReviewBudgetingPlanningPage;

export const CompensationReviewBudgetingPlanningPageQuery = graphql`
  query CompensationReviewBudgetingPlanningPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        crbpPageIntro {
          heading
          header
          description
        }
        crbpPageBlurbs {
          heroImage {
            publicURL
          }
          heading
          header
          description
          blurbs {
            image {
              publicURL
            }
            title
            text
          }
        }
        crbpPageClosing {
          heading
          content {
            image {
                publicURL
            }
            text
          }
          buttonLink
          buttonText
          useImage
          heroImage {
            publicURL
          }
        }
        crbpPagePricing {
          title
          subtitle
          buttonLink
          buttonText
        }
        crbpPageHashtags {
          title
        }
      }
    }
  }
`;