import React from 'react'
import CTAButton from "./CTAButton";
import PropTypes from "prop-types";

const ViewPricingSection = (props) => {
    const { title, subtitle, buttonLink, buttonText } = props
    return (
        <>
            <div className="container">
                <div className="block has-text-centered">
                    <h2 className="title is-2 has-text-weight-bold">{title}</h2>
                    { subtitle && <h3 className="subtitle is-h3">{subtitle}</h3>}
                </div>
                <div className="block has-text-centered mt-6">
                    <CTAButton text={buttonText} link={buttonLink} noIcon />
                </div>
            </div>
        </>
    )
}

ViewPricingSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string
}

export default ViewPricingSection