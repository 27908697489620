import React from 'react'
import PropTypes from "prop-types"

const HashtagHidden = (props) => {
    const {
        hashtags,
    } = props
    return(
        <>
            <div style={{ display: "none"}}>
              {
                hashtags.map((hashtag, index) => {
                  return <p key={index}>{`#${hashtag?.title}`}</p>
                })
              }
            </div>
        </>
    )
}

HashtagHidden.propTypes = {
  hashtags: PropTypes.array,
}

export default HashtagHidden