import React from 'react'
import PropTypes from "prop-types";

import SimpleHeader from "./SimpleHeader";

const ThreeBlurbsSection = (props) => {
    const {
       heroImage,
       heading,
       header,
       description,
       buttonLink,
       buttonText,
       blurbs,
       isHeader = false,
       replaceTextWithImage = false,
       textColumnSize = "",
       subheaderSize= "",
       playIcon = false
    } = props
    const MIN_WIDTH = 9
    const INTRO_COLUMN_MAP = [
      { left: 3, middle: 9, right: 0 },
      { left: 1, middle: 10, right: 1 },
      { left: 0, middle: 9, right: 3 }
    ]

    const renderText = () => {
        return (
            <>
                <SimpleHeader
                  heading={heading}
                  header={header}
                  subheader={description}
                  buttonLink={buttonLink}
                  buttonText={buttonText}
                  isPageHeader={isHeader}
                  columnSize={textColumnSize}
                  subheaderSize={subheaderSize}
                  playIcon={playIcon}
                />
            </>
        )
    }

    const renderImage = () => {
        return (
            <>
                <div className={`column ${textColumnSize}`}>
                    <div className="block">
                        <h2 className="title is-2 has-text-weight-bold">{heading}</h2>
                    </div>
                    <div className="block">
                        <img height={6000} width={6000} src={heroImage.publicURL} alt={heroImage.title} />
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            { replaceTextWithImage ? renderImage() : renderText()}

            <div className="column is-7">
              { blurbs.slice(0, 3).map((value, index) => {
                const renderColumn = width => {
                  if (!width)
                    return
                  if (width < MIN_WIDTH)
                    return <div className={`column is-${width} is-hidden-touch`}/>

                    return (
                    <div className={`column is-${width}`}>
                      <div className="columns is-mobile" style={{ paddingLeft: '3em'}}>
                        <div className="column is-3 blurb-icon">
                          <div className="blurb-container has-text-centered no-mobile-padding">
                            <img className="blurb-image img-125" src={value.image.publicURL || ''} alt={value.title} />
                          </div>
                        </div>
                        <div className="column is-9">
                          <div className="block">
                              {value.title ? (
                                  <h3 className="subtitle is-h3" style={{ margin: '1em 0 .5em' }}>{value.title}</h3>
                              ) : React.Fragment }
                            <p className="is-h3" style={value.title ? { marginBottom: '2em' } : { marginBottom: '2em', marginTop: '3em', marginLeft: '0.5em' }}>{value.text}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                return (
                  <div key={index} className="columns columns-intro">
                    { renderColumn(INTRO_COLUMN_MAP[index].left) }
                    { renderColumn(INTRO_COLUMN_MAP[index].middle) }
                    { renderColumn(INTRO_COLUMN_MAP[index].right) }
                  </div>
                )
              })}
            </div>
      </>
    )
}

ThreeBlurbsSection.propTypes = {
  heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  header: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  blurbs: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  isHeader: PropTypes.bool,
  replaceTextWithImage: PropTypes.bool,
  textColumnSize: PropTypes.string,
  subheaderSize: PropTypes.string,
}

export default ThreeBlurbsSection